import AtmCase from '@/models/AtmCase'
import { Model } from '@vuex-orm/core'
export default class GeneralSettings extends Model {
    static entity = "GeneralSettings"

    static fields() {
        return {
            uuid: this.uid(),
            // Building and Housing
            individualBuildingLength: this.number(0),
            individualHousingLength: this.number(0),
            collectiveBuildingLength: this.number(0),
            collectiveHousingLength: this.number(0),
            isConstructionNew: this.string('yes'),
            hasExtension: this.string('no'),
            // Portions
            effectivePortionsLength: this.number(0),
            // Build Permit
            submissionDate: this.attr(""),
            issuanceDate: this.attr(""),
            buildPermitNumber: this.attr(""),
            // Neighborhood
            groundTransportInfrastructure: this.string(''),
            aerodromeNoiseZone: this.string(""),
            facadeInsulation: this.string(''),
            socotecReportNumber: this.string(""),
            // EO Acoustic
            acousticMission: this.string(''),
            acousticContributor: this.string(''),
            // Technical Inspector
            tiMissionConception: this.boolean(false),
            tiMissionExecution: this.boolean(false),
            tiMissionPhh: this.boolean(false),
            tiMissionContributor: this.string(''),
            // Case
            caseInfo: this.string(''),
            atmCase: this.hasOne(AtmCase, 'atmUuid', 'caseInfo')
        }
    }
}