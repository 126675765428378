import { socioGrpcClient } from "@/setup/socioGrpcClient";
import cloneDeep from 'lodash/cloneDeep';
const grpcClient = socioGrpcClient.contributors.contributors;
const google_protobuf_struct_pb = require("google-protobuf/google/protobuf/struct_pb.js");


export const contributorClient = {
  list: async (metadata) => {
    const request = new grpcClient.ContributorCompanyProjectRoleLightListRequest();

    const response =
      await grpcClient.ContributorControllerPromiseClient.utils.listAllObjects(
        request,
        metadata
      );

    return response
  },
  create: async (contributor) => {
    const new_contributor = cloneDeep(contributor);
    new_contributor.metadata = new google_protobuf_struct_pb.Struct.fromJavaScript(contributor.metadata)

    const request = socioGrpcClient.javascriptToRequest(
      grpcClient.ContributorInputRequest,
      new_contributor
    );
    const response =
      await grpcClient.ContributorControllerPromiseClient.create(
        request,
        {}
      );

    return response.toObject();
  },
  update: async (contributor) => {
    const request = socioGrpcClient.javascriptToRequest(
      grpcClient.ContributorInputRequest,
      contributor
    );

    const response =
      await grpcClient.ContributorControllerPromiseClient.update(
        request,
        {}
      );

    return response.toObject();
  },
  destroy: async (contributor) => {
    const request = socioGrpcClient.javascriptToRequest(
      grpcClient.ContributorDestroyRequest,
      contributor
    );

    await grpcClient.ContributorControllerPromiseClient.destroy(
      request,
      {}
    );

    return null
  },
};
